<template>
  <div class="article">
    <div class="article-content" v-html="info.content"></div>
  </div>
</template>

<script>
import {getProtocol} from '@/api/product'

export default {
  data() {
    return {
      type: undefined,
      info: {
        title: undefined,
        content: undefined
      }
    }
  },
  computed: {},
  components: {},
  created() {
    this.type = this.$route.query.type
    this.doQuery()
  },
  methods: {
    doQuery() {
      getProtocol({type: this.type}).then((res) => {
        this.info = res.data
      }).catch(() => {
        this.$toast.fail("数据加载失败");
      })
    }
  }
}
</script>

<style lang="less" scoped>
.article {
  margin: 10px;
  background-color: #FFFFFF;
}

.title {
  color: #172B4D;
  font-size: 16px;
  text-align: center;
}

/deep/ .article-content img {
  max-width: 100%;
}
</style>
